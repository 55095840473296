import { Button } from "@mui/material";
import { Link } from "react-router";
import { useLocalization } from "../hooks/useLocalization";
import ErrorIcon from "../icons/ErrorIcon";
import InfoPage from "./common/InfoPage";

export default function ErrorPage() {
  const { pages } = useLocalization();

  return (
    <InfoPage
      Icon={<ErrorIcon />}
      title={pages.error.title}
      subtitle={pages.error.subtitle}
      ActionComponent={
        <Button component={Link} to="/" sx={{ width: "10rem" }} color="primary" variant="outlined">
          {pages.error.button}
        </Button>
      }
    />
  );
}
