import { Button, Grid2, Link, Typography } from "@mui/material";
import { useLocalization } from "../hooks/useLocalization";
import UserIcon from "../icons/UserIcon";
import { pageNames } from "../pages";
import StyledPage from "./common/StyledPage";
import { ReferenceVariables } from "./RedirectKeys";

export default function UserNotAuthorizedPage() {
  const { pages: locale } = useLocalization();

  return (
    <StyledPage sx={{ alignItems: "center", justifyContent: "center" }}>
      <Grid2 container sx={{ gap: 3, justifyContent: "center", width: "100%" }}>
        <Grid2 container sx={{ justifyContent: "center", width: "100%" }}>
          <UserIcon />
        </Grid2>
        <Grid2
          container
          sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", pt: 1, gap: 1, width: "100%" }}
        >
          <Typography variant="h6">{locale.unauthorized.title}</Typography>
          <Typography variant="body1" color="secondary" sx={{ textAlign: "center" }}>
            {locale.unauthorized.text}
          </Typography>
        </Grid2>
        <Button
          LinkComponent={Link}
          href={`/${pageNames.signIn}/?${ReferenceVariables.Prompt}=select_account`}
          variant="contained"
        >
          Sign In with another account
        </Button>
      </Grid2>
    </StyledPage>
  );
}
