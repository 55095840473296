import { Box, Grid2, Stack, Typography, useTheme } from "@mui/material";
import DefaultIcon from "../../images/info_page_icon.svg";
import { infoPageStyle } from "./StyledPage";

interface Props {
  emphasize?: string;
  title: string;
  subtitle: string;
  errorDetails?: string;
  ActionComponent?: JSX.Element;
  Icon?: JSX.Element;
}

export default function InfoPage({ title, subtitle, errorDetails, emphasize, ActionComponent, Icon }: Props) {
  const theme = useTheme();
  return (
    <Grid2
      container
      sx={{
        ...infoPageStyle(theme),
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        spacing: 3,
        gap: 2,
      }}
    >
      <Grid2>
        <Stack spacing={1} alignItems="center">
          <Box mt={-20} mb={5}>
            {Icon || <img src={Icon || DefaultIcon} alt="logo" />}
          </Box>
          {emphasize && <Typography variant="h1">{emphasize}</Typography>}
        </Stack>
      </Grid2>
      <Grid2>
        <Stack spacing={1} alignItems="center" maxWidth="62rem">
          <Typography variant="h3">{title}</Typography>
          {errorDetails && (
            <Typography variant="subtitle1" color="error">
              {errorDetails}
            </Typography>
          )}
          <Typography variant="body1" textAlign="center">
            {subtitle}
          </Typography>
        </Stack>
      </Grid2>
      <Grid2>
        <Box>{ActionComponent}</Box>
      </Grid2>
    </Grid2>
  );
}
