import { Grid2, Typography } from "@mui/material";
import { useLocalization } from "../hooks/useLocalization";
import VerificationLinkNotValid from "../icons/VerificationLinkNotValid";
import StyledPage from "./common/StyledPage";

export default function InvalidLinkPage() {
  const { pages: locale } = useLocalization();

  return (
    <StyledPage sx={{ alignItems: "center", justifyContent: "center" }}>
      <Grid2 container sx={{ gap: 3, width: "100%" }}>
        <Grid2 container sx={{ justifyContent: "center", width: "100%" }}>
          <VerificationLinkNotValid />
        </Grid2>
        <Grid2
          container
          sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", pt: 1, gap: 1, width: "100%" }}
        >
          <Typography variant="h6">{locale.invalid_link.header}</Typography>
          <Typography variant="body1" color="secondary" sx={{ textAlign: "center" }}>
            {locale.invalid_link.text}
          </Typography>
        </Grid2>
      </Grid2>
    </StyledPage>
  );
}
