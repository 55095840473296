import React from "react";
import { Navigate, useSearchParams } from "react-router";
import { api } from "../api/api";
import { IdpType, idpTypes } from "../api/types";
import AuthenticationFailed from "../auth/AuthenticationFailed";
import { getStatusCodeFromResponseError } from "../auth/axiosHelper";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { pageNames } from "../pages";
import { defined } from "../utils/typeHelper";
import { getCodeReturnTypeRedirectUrl, getRedirectUrl } from "../variables";
import { RedirectKeys, ReferenceVariables } from "./RedirectKeys";

export default function SignInAuthPage() {
  const [tokenResp, setTokenResp] = React.useState<{
    auth: boolean;
    isEmailVerified: boolean;
    unauthorized?: boolean;
  }>();
  const [authCode, verificationId, redirectUrlState, idp, returnType] = useOauthState();

  const acquireToken = React.useCallback(async () => {
    try {
      const resp = await api.requestLoginToken(defined(authCode), idp);

      if (resp.data?.isEmailVerified === true) {
        setTokenResp({ auth: true, isEmailVerified: true });
      } else {
        setTokenResp({ auth: true, isEmailVerified: false });
      }
    } catch (error) {
      const statusCode = getStatusCodeFromResponseError(error);
      if (statusCode === 403) {
        setTokenResp({ auth: false, isEmailVerified: false, unauthorized: true });
      } else {
        setTokenResp({ auth: false, isEmailVerified: false });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, verificationId]);

  React.useEffect(() => {
    if (returnType === "code") return;
    if (!authCode) return;
    if (tokenResp === undefined) {
      acquireToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnType, acquireToken]);

  if (!authCode) {
    return <Navigate to="/" />;
  }

  if (returnType === "code") {
    window.location.href = getCodeReturnTypeRedirectUrl(authCode, redirectUrlState, idp);
    return <></>;
  }

  if (tokenResp?.unauthorized === true) {
    return <Navigate to={`/${pageNames.notAuthorized}`} />;
  }

  switch (tokenResp?.auth) {
    case undefined: {
      return <FullScreenLoader title="Please wait." subtitle="We're authorizing you..." />;
    }
    case false: {
      return <AuthenticationFailed />;
    }
    case true: {
      if (verificationId) {
        return <Navigate to={`/verification?verificationId=${verificationId}`} />;
      } else if (tokenResp.isEmailVerified) {
        window.location.href = getRedirectUrl(redirectUrlState);
        return <></>;
      } else if (!tokenResp.isEmailVerified) {
        return <Navigate to={`/${pageNames.verificationNotCompleted}`} />;
      }
      return <></>;
    }
    default:
      return <></>;
  }
}

function useOauthState(): [
  string | undefined,
  string | undefined,
  string | undefined,
  IdpType | undefined,
  string | undefined,
] {
  const [params] = useSearchParams();
  const authCode = params.get(RedirectKeys.RedirectCode) || undefined;
  const oauthState = params.get(RedirectKeys.RedirectState) || "";
  const stateObj = React.useMemo(() => getStateParams(oauthState), [oauthState]);

  const verificationId = stateObj[ReferenceVariables.VerificationId];
  const idpFromState = stateObj[ReferenceVariables.Idp] as IdpType;
  const idp = idpTypes.includes(idpFromState) ? idpFromState : undefined;
  const returnType = stateObj[ReferenceVariables.ReturnType];

  delete stateObj[ReferenceVariables.VerificationId];
  delete stateObj[ReferenceVariables.Idp];

  return [authCode, verificationId, JSON.stringify(stateObj), idp, returnType];
}

function getStateParams(oauthState: string): Record<string, string> {
  try {
    return JSON.parse(oauthState);
  } catch {
    return {};
  }
}
