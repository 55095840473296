export const pageNames = {
  signIn: "signin",
  signUp: "signup",
  oauthSignIn: "oauth_signin",
  oauthSignUp: "oauth_signup",
  logout: "logout",
  loggedOut: "loggedout",
  invalidLink: "invalidlink",
  verification: "verification",
  emailSent: "emailsent",
  verificationNotCompleted: "verificationnotcompleted",
  error: "error",
  notAuthorized: "unauthorized",
};
