import React from "react";
import { api } from "../api/api";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { useLocalization } from "../hooks/useLocalization";
import { pageNames } from "../pages";

const postLogoutRedirectParam = "post_logout_redirect_uri";

export default function LogoutPage() {
  const locale = useLocalization();
  const page = locale.pages.logout;

  const redirectUri = React.useMemo(() => `${new URL(window.location.href).origin}/${pageNames.loggedOut}`, []);

  React.useEffect(() => {
    const navigateToErrorPage = () => (window.location.href = "/error");

    api
      .logout()
      .then((response) => {
        if (response.success) {
          try {
            const url = new URL(response.data.redirectToLogoutUrl);
            url.searchParams.append(postLogoutRedirectParam, redirectUri);
            window.location.href = url.toString();
          } catch {
            navigateToErrorPage();
          }
        } else {
          navigateToErrorPage();
        }
      })
      .catch(navigateToErrorPage);
  }, [redirectUri]);

  return <FullScreenLoader title={page.title} subtitle={page.subtitle} />;
}
