import { Typography } from "@mui/material";
import { useLocalization } from "../../hooks/useLocalization";
import MicrosoftIcon from "../../icons/MicrosoftIcon";
import { LoginButton } from "../LoginButton";

interface Props {
  disabled?: boolean;
  onClick: () => void;
}

const MicrosoftButton = ({ disabled, onClick }: Props) => {
  const { components: locale } = useLocalization();

  return (
    <LoginButton onClick={onClick} sx={{ py: 1.1 }} buttonProps={{ disabled }}>
      <MicrosoftIcon disabled={disabled} />
      <Typography
        sx={(t) => ({
          fontSize: "16px",
          fontWeight: 500,
          color: disabled ? t.palette.text.secondary : t.palette.text.primary,
        })}
      >
        {locale.microsoftButton.text}
      </Typography>
    </LoginButton>
  );
};

export default MicrosoftButton;
