import { Box, Grid2, Stack, Typography } from "@mui/material";
import AuthLoaderSvg from "./AuthLoaderSvg";

interface Props {
  title: string;
  subtitle: string;
}

export default function FullScreenLoader({ title, subtitle }: Props) {
  return (
    <Grid2
      container
      sx={{ alignItems: "center", justifyContent: "center", flexDirection: "column", spacing: 3, width: "100%" }}
    >
      <Grid2>
        <Box mt={-20} width={240}>
          <AuthLoaderSvg />
        </Box>
      </Grid2>
      <Grid2>
        <Stack spacing={1} alignItems="center">
          <Typography variant="h3">{title}</Typography>
          <Typography variant="body1">{subtitle}</Typography>
        </Stack>
      </Grid2>
    </Grid2>
  );
}
