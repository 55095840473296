import { LoadingButton } from "@mui/lab";
import { Grid2, Typography, useTheme } from "@mui/material";
import React from "react";
import { Navigate } from "react-router";
import { api } from "../api/api";
import { getMessageFromResponseError } from "../auth/axiosHelper";
import { useUserContext } from "../contexts/UserContext";
import { useLocalization } from "../hooks/useLocalization";
import VerifyEmailIcon from "../icons/VerifyEmailIcon";
import ErrorComponent from "./ErrorComponent";
import StyledPage from "./common/StyledPage";

export default function EmailSentPage() {
  const theme = useTheme();
  const { pages: locale } = useLocalization();
  const { email } = useUserContext();

  const [sending, setSending] = React.useState(false);
  const [sendingFailed, setSendingFailed] = React.useState(false);
  const [error, setError] = React.useState<string>();

  const resendEmail = React.useCallback(() => {
    setSending(true);
    setTimeout(async () => {
      try {
        await api.resendEmailVerification();
        setSendingFailed(false);
      } catch (error) {
        const responseError = getMessageFromResponseError(error);
        if (responseError) {
          setError(responseError);
        }
        setSendingFailed(true);
      } finally {
        setSending(false);
      }
    }, 0);
  }, [setSending]);

  if (email === undefined) {
    return <Navigate to="/" />;
  }

  return (
    <StyledPage sx={{ alignItems: "center", justifyContent: "center" }}>
      <Grid2 container sx={{ gap: 2, width: "100%" }}>
        <Grid2 container sx={{ justifyContent: "center", width: "100%" }}>
          <VerifyEmailIcon />
        </Grid2>
        <Grid2 container sx={{ alignItems: "center", flexDirection: "column", pt: 1, gap: 1, width: "100%" }}>
          <Typography variant="h6">Verify your Email</Typography>
          <Typography variant="body1" color="secondary" sx={{ textAlign: "center" }}>
            {locale.emailsent.text1}{" "}
            <strong style={{ color: theme.palette.text.primary, textDecoration: "none", fontWeight: "500" }}>
              {email}
            </strong>{" "}
            {locale.emailsent.text2}
          </Typography>
        </Grid2>
        <ErrorComponent failed={!sending && sendingFailed} text={error || locale.emailsent.error} />
        <Grid2
          container
          sx={{ flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 1, width: "100%" }}
        >
          <Typography variant="body1" color="secondary" sx={{ textAlign: "center" }}>
            {locale.emailsent.not_received_email}
          </Typography>
          <LoadingButton variant="text" size="small" loading={sending} disabled={sending} onClick={resendEmail}>
            Resend
          </LoadingButton>
        </Grid2>
      </Grid2>
    </StyledPage>
  );
}
