import { Button } from "@mui/material";
import { Link } from "react-router";
import { useLocalization } from "../hooks/useLocalization";
import LoggedOutIcon from "../icons/LoggedOutIcon";
import { pageNames } from "../pages";
import InfoPage from "./common/InfoPage";

export default function LoggedOutPage() {
  const locale = useLocalization();
  const page = locale.pages.loggedout;

  return (
    <InfoPage
      Icon={<LoggedOutIcon />}
      title={page.title}
      subtitle={page.subtitle}
      ActionComponent={
        <Button component={Link} to={`/${pageNames.signIn}`} variant="outlined" color="primary" sx={{ width: "10rem" }}>
          {page.button}
        </Button>
      }
    />
  );
}
