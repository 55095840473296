import React from "react";
import { Navigate } from "react-router";
import { api } from "../api/api";
import AuthenticationFailed from "../auth/AuthenticationFailed";
import { getMessageFromResponseError } from "../auth/axiosHelper";
import FullScreenLoader from "../components/fullScreenLoader/FullScreenLoader";
import { useUserContext } from "../contexts/UserContext";
import { useOauthState } from "../hooks/useOauthState";
import { pageNames } from "../pages";
import { defined } from "../utils/typeHelper";
import { getRedirectUrl } from "../variables";

interface AuthState {
  authenticated: boolean | undefined;
  emailVerified: boolean;
  authenticationError: string | undefined;
}

export default function SignUpAuthPage() {
  const { update } = useUserContext();
  const [authState, setAuthState] = React.useState<AuthState>({
    authenticated: undefined,
    emailVerified: false,
    authenticationError: undefined,
  });
  const [authCode, invitationId, idp] = useOauthState();

  const acquireToken = React.useCallback(async () => {
    try {
      const resp = await api.requestSignupToken(defined(authCode), defined(invitationId), defined(idp));
      update(resp.data.email);
      setAuthState({ authenticated: true, emailVerified: resp.data.isEmailVerified, authenticationError: undefined });
    } catch (error) {
      setAuthState({
        authenticated: false,
        emailVerified: false,
        authenticationError: getMessageFromResponseError(error),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authCode, invitationId]);

  React.useEffect(() => {
    if (!authCode || !invitationId) return;
    if (authState.authenticated === undefined) {
      acquireToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!authCode || !invitationId) {
    return <Navigate to="/" />;
  }

  if (authState.authenticated === true && authState.emailVerified) {
    window.location.href = getRedirectUrl();
    return null;
  }

  if (authState.authenticated === true && !authState.emailVerified) {
    return <Navigate to={`/${pageNames.emailSent}`} />;
  }

  switch (authState.authenticated) {
    case undefined: {
      return <FullScreenLoader title="Please wait." subtitle="We're authorizing you..." />;
    }
    case false: {
      return <AuthenticationFailed errorDetails={authState.authenticationError} />;
    }
    default:
      return <></>;
  }
}
