import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import { setUpAxios } from "./auth/axiosHelper";
import { UserContextProvider } from "./contexts/UserContext";
import { initAppInsights } from "./logging";
import { pageNames } from "./pages";
import EmailSentPage from "./pages/EmailSentPage";
import ErrorPage from "./pages/ErrorPage";
import InvalidLinkPage from "./pages/InvalidLinkPage";
import LoggedOutPage from "./pages/LoggedOutPage";
import LogoutPage from "./pages/LogoutPage";
import SignInAuthPage from "./pages/SignInAuthPage";
import SignInPage from "./pages/SignInPage";
import SignUpAuthPage from "./pages/SignUpAuthPage";
import SignUpPage from "./pages/SignUpPage";
import UserNotAuthorizedPage from "./pages/UserNotAuthorizedPage";
import VerificationNotCompletedPage from "./pages/VerificationNotCompletedPage";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import { themeOptions } from "./theme";

initAppInsights({ appId: "LoginPortal" });
setUpAxios();

const theme = createTheme(themeOptions);

export default function Entry() {
  const [email, setEmail] = useState<string>();

  return (
    <ThemeProvider theme={theme}>
      <UserContextProvider user={{ email, update: setEmail }}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Navigate to={pageNames.signIn} />} />
          <Route path={pageNames.signIn} element={<SignInPage />} />
          <Route path={pageNames.signUp} element={<SignUpPage />} />
          <Route path={pageNames.oauthSignIn} element={<SignInAuthPage />} />
          <Route path={pageNames.oauthSignUp} element={<SignUpAuthPage />} />
          <Route path={pageNames.logout} element={<LogoutPage />} />
          <Route path={pageNames.loggedOut} element={<LoggedOutPage />} />
          <Route path={pageNames.emailSent} element={<EmailSentPage />} />
          <Route path={pageNames.verification} element={<VerifyEmailPage />} />
          <Route path={pageNames.invalidLink} element={<InvalidLinkPage />} />
          <Route path={pageNames.verificationNotCompleted} element={<VerificationNotCompletedPage />} />
          <Route path={pageNames.notAuthorized} element={<UserNotAuthorizedPage />} />
          <Route path={pageNames.error} element={<ErrorPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </UserContextProvider>
    </ThemeProvider>
  );
}
