import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router";
import Entry from "./Entry";
import { defined } from "./utils/typeHelper";

const root = createRoot(defined(document.getElementById("root"), "root element not found"));

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Entry />
    </BrowserRouter>
  </React.StrictMode>
);
